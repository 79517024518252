;(function ( $, window, document, undefined ) {

    function debounce(fn, delay) {
      var timer = null;
      return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    }
    var preload_img = (function( $ ){
      'use strict';
      var $target, tick,
      listen = function() {
          var debouncedLayout = debounce( tick, 300 );
          $target.find('img').each(function() {
              var proxyImage;
              if ( this.complete && this.naturalWidth !== undefined ) {
                  return;
              }
              proxyImage = new Image();
              $( proxyImage ).one('load', debouncedLayout);
              proxyImage.src = this.src;
          });
          setTimeout(function() {
              debouncedLayout();
          }, 500);
      },
      init = function( _target, _tick ) {
        $target = $(_target);
        tick = _tick;
        setTimeout(function() {
          listen();
        }, 100);
      };
      return init; 
    })(jQuery);

    function AjaxPagination( container_selector, button_class, auto_scroll, auto_scroll_offset ){
        this.container_selector = container_selector;
        this.$container = $(container_selector);
        this.button_class = button_class;
        this.$events = $({});
        this.loading = false;
        this.auto_scroll = auto_scroll || 0;
        this.auto_scroll_offset = auto_scroll_offset || 0;
        this.last_page = window.location.href;
        this.last_page_title = $(document).find("title").text();
        this.prevent_event_change = false;
        if(this.attachClick()){
            this.attachScroll();
            this.preload_img();
        }
    }
    AjaxPagination.prototype.on = function( event, fn ){
        this.$events.on(event, fn);
    };
    AjaxPagination.prototype.off = function( event ){
        this.$events.off(event);
    };
    AjaxPagination.prototype.getButton = function(){
        return $("." + this.button_class + ":not(.loaded)").eq(0);
    };
    AjaxPagination.prototype.preload_img = function(){
        var _this = this;
        preload_img( this.$container, function(){
            _this.$events.trigger("ajaxpagination:image-loaded");
        });
    };
    AjaxPagination.prototype.attachClick = function(){
        this.loading = false;
        var _this = this,
            $button = this.getButton(),
            href = $button.attr("href"),
            click = function( event ){
                event.preventDefault();
                if(!_this.loading){
                    _this.loading = true;
                    _this.$events.trigger("ajaxpagination:loading", [$button]);
                    $.get( href, function( data ){
                        _this.auto_scroll--;
                        $button.addClass("loaded");
                        var $output = $('<output>').append($.parseHTML(data, null, true)),
                            $title = $("title", $output),
                            $items = $(_this.container_selector + " > *", $output),
                            $new_button = $("." + _this.button_class, $output);
                        $items.eq(0).addClass('ajaxpagination__page').data("href",href).data("title",$title.text());
                        _this.$container.append( $items );
                        $items.find('img').each(function(index, img){ img.outerHTML = img.outerHTML; });
                        $button.off( "click", click );
                        _this.$events.trigger("ajaxpagination:loaded", [$button, $items, $new_button]);
                        _this.preload_img();
                        if(!_this.attachClick()){
                            _this.$events.trigger("ajaxpagination:last");
                        }
                    });    
                }
            };
        if($button.length && href){
            $button.on( "click", click );
            return true;
        }
        return false;
    };
    AjaxPagination.prototype.attachScroll = function(){
        var _this = this, ttrl;
        this.$container.children().eq(0).addClass('ajaxpagination__page').data("href",this.last_page).data("title",this.last_page_title);
        $(window).on('scroll', function(){
            clearTimeout(ttrl);
            ttrl = setTimeout(function(){
                var scrollTop = $(window).scrollTop(),
                    height = $(window).height(),
                    middle = scrollTop + height / 2,
                    min = false,
                    $nearest, calc;
                _this.$container.find(".ajaxpagination__page").each(function(){
                    calc = Math.abs(middle - $(this).offset().top);
                    if(min === false || calc < min){
                        $nearest = $(this);
                        min = calc;
                    }
                });
                if($nearest && $nearest.length){
                    _this.pageChange($nearest.data("href"), $nearest.data("title"));
                }
                if(_this.auto_scroll > 0 && !_this.loading){
                    if($(window).scrollTop() + $(window).height() > _this.$container.offset().top + _this.$container.height() - _this.auto_scroll_offset) {
                        _this.getButton().click();
                    }
                }
            },100);
        });
    };
    AjaxPagination.prototype.pageChange = function( url, title ){
        if(url !== this.last_page){
            this.last_page = url;
            this.last_page_title = title;
            if(!this.prevent_event_change){
                this.$events.trigger("ajaxpagination:change", [this.last_page, this.last_page_title]);
            }
        }
    };
    AjaxPagination.prototype.goto = function( url, prevent_event_change ){
        var $page, _this = this;
        this.prevent_event_change = prevent_event_change === true;
        url = url.replace(/\/$/, "");
        this.$container.find(".ajaxpagination__page").each(function(){
            if($(this).data("href").replace(/\/$/, "") === url){
                $page = $(this);
                return false;
            }
        });
        if($page){
            var $body = $("html, body");
            $body.stop().animate({scrollTop:$page.offset().top - $(window).height() / 2}, 400, function(){
                if(_this.prevent_event_change){
                    _this.last_page = $page.data("href");
                    _this.last_page_title = $page.data("title");
                }
                _this.prevent_event_change = false;
            });
        }
    };
    
    window.ajaxpagination = AjaxPagination;
    
})( jQuery, window, document );