/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

console.log('main.js');

// Utility function
function Util () {};

/* class manipulation functions */
Util.hasClass = function(el, className) {
	return el.classList.contains(className);
};

Util.addClass = function(el, className) {
	var classList = className.split(' ');
 	el.classList.add(classList[0]);
 	if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function(el, className) {
	var classList = className.split(' ');
	el.classList.remove(classList[0]);	
	if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function(el, className, bool) {
	if(bool) Util.addClass(el, className);
	else Util.removeClass(el, className);
};

Util.setAttributes = function(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

/* DOM manipulation */
Util.getChildrenByClassName = function(el, className) {
  var children = el.children,
    childrenByClass = [];
  for (var i = 0; i < children.length; i++) {
    if (Util.hasClass(children[i], className)) childrenByClass.push(children[i]);
  }
  return childrenByClass;
};

Util.is = function(elem, selector) {
  if(selector.nodeType){
    return elem === selector;
  }

  var qa = (typeof(selector) === 'string' ? document.querySelectorAll(selector) : selector),
    length = qa.length;

  while(length--){
    if(qa[length] === elem){
      return true;
    }
  }

  return false;
};

/* Animate height of an element */
Util.setHeight = function(start, to, element, duration, cb, timeFunction) {
	var change = to - start,
	    currentTime = null;

  var animateHeight = function(timestamp){  
    if (!currentTime) currentTime = timestamp;         
    var progress = timestamp - currentTime;
    if(progress > duration) progress = duration;
    var val = parseInt((progress/duration)*change + start);
    if(timeFunction) {
      val = Math[timeFunction](progress, start, to - start, duration);
    }
    element.style.height = val+"px";
    if(progress < duration) {
        window.requestAnimationFrame(animateHeight);
    } else {
    	if(cb) cb();
    }
  };
  
  //set the height of the element before starting animation -> fix bug on Safari
  element.style.height = start+"px";
  window.requestAnimationFrame(animateHeight);
};

/* Smooth Scroll */
Util.scrollTo = function(final, duration, cb, scrollEl) {
  var element = scrollEl || window;
  var start = element.scrollTop || document.documentElement.scrollTop,
    currentTime = null;

  if(!scrollEl) start = window.scrollY || document.documentElement.scrollTop;
      
  var animateScroll = function(timestamp){
  	if (!currentTime) currentTime = timestamp;        
    var progress = timestamp - currentTime;
    if(progress > duration) progress = duration;
    var val = Math.easeInOutQuad(progress, start, final-start, duration);
    element.scrollTo(0, val);
    if(progress < duration) {
      window.requestAnimationFrame(animateScroll);
    } else {
      cb && cb();
    }
  };

  window.requestAnimationFrame(animateScroll);
};

/* Move Focus */
Util.moveFocus = function (element) {
  if( !element ) element = document.getElementsByTagName("body")[0];
  element.focus();
  if (document.activeElement !== element) {
    element.setAttribute('tabindex','-1');
    element.focus();
  }
};

/* Misc */

Util.getIndexInArray = function(array, el) {
  return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function(property, value) {
  return CSS.supports(property, value);
};

// merge a set of user options into plugin defaults
// https://gomakethings.com/vanilla-javascript-version-of-jquery-extend/
Util.extend = function() {
  // Variables
  var extended = {};
  var deep = false;
  var i = 0;
  var length = arguments.length;

  // Check if a deep merge
  if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
    deep = arguments[0];
    i++;
  }

  // Merge the object into the extended object
  var merge = function(obj) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        if (deep && Object.prototype.toString.call(obj[prop]) === '[object Object]') {
          extended[prop] = Util.extend(true, extended[prop], obj[prop]); // Corrección aquí
        } else {
          extended[prop] = obj[prop];
        }
      }
    }
  };

  // Loop through each object and conduct a merge
  for ( ; i < length; i++ ) {
    var obj = arguments[i];
    merge(obj);
  }

  return extended;
};

// Check if Reduced Motion is enabled
Util.osHasReducedMotion = function() {
  if(!window.matchMedia) return false;
  var matchMediaObj = window.matchMedia('(prefers-reduced-motion: reduce)');
  if(matchMediaObj) return matchMediaObj.matches;
  return false; // return false if not supported
}; 

/* Animation curves */
Math.easeInOutQuad = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};

Math.easeInQuart = function (t, b, c, d) {
	t /= d;
	return c*t*t*t*t + b;
};

Math.easeOutQuart = function (t, b, c, d) { 
  t /= d;
	t--;
	return -c * (t*t*t*t - 1) + b;
};

Math.easeInOutQuart = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2*t*t*t*t + b;
	t -= 2;
	return -c/2 * (t*t*t*t - 2) + b;
};

Math.easeOutElastic = function (t, b, c, d) {
  var s=1.70158;var p=d*0.7;var a=c;
  if (t==0) return b;  if ((t/=d)==1) return b+c;  if (!p) p=d*.3;
  if (a < Math.abs(c)) { a=c; var s=p/4; }
  else var s = p/(2*Math.PI) * Math.asin (c/a);
  return a*Math.pow(2,-10*t) * Math.sin( (t*d-s)*(2*Math.PI)/p ) + c + b;
};


/* JS Utility Classes */

// make focus ring visible only for keyboard navigation (i.e., tab key) 
(function() {
  var focusTab = document.getElementsByClassName('js-tab-focus'),
    shouldInit = false,
    outlineStyle = false,
    eventDetected = false;

  function detectClick() {
    if(focusTab.length > 0) {
      resetFocusStyle(false);
      window.addEventListener('keydown', detectTab);
    }
    window.removeEventListener('mousedown', detectClick);
    outlineStyle = false;
    eventDetected = true;
  };

  function detectTab(event) {
    if(event.keyCode !== 9) return;
    resetFocusStyle(true);
    window.removeEventListener('keydown', detectTab);
    window.addEventListener('mousedown', detectClick);
    outlineStyle = true;
  };

  function resetFocusStyle(bool) {
    var outlineStyle = bool ? '' : 'none';
    for(var i = 0; i < focusTab.length; i++) {
      focusTab[i].style.setProperty('outline', outlineStyle);
    }
  };

  function initFocusTabs() {
    if(shouldInit) {
      if(eventDetected) resetFocusStyle(outlineStyle);
      return;
    }
    shouldInit = focusTab.length > 0;
    window.addEventListener('mousedown', detectClick);
  };

  initFocusTabs();
  window.addEventListener('initFocusTabs', initFocusTabs);
}());

function resetFocusTabsStyle() {
  window.dispatchEvent(new CustomEvent('initFocusTabs'));
};

jQuery.event.special.touchstart = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.touchmove = {
	setup: function( _, ns, handle ) {
		this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
	}
};
jQuery.event.special.wheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("wheel", handle, { passive: true });
	}
};
jQuery.event.special.mousewheel = {
	setup: function( _, ns, handle ){
		this.addEventListener("mousewheel", handle, { passive: true });
	}
};


(function($) {


	var History = window.History;

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

        console.log('update v6...');


        /*----------------------------------------------------------------------------------------------------
        Login
        ----------------------------------------------------------------------------------------------------*/
        var cookieRealtyflowCheckUser = 'realtyflow_chck_user';
        $('.realtyflow__login').each(function() {

          var _this           = $(this),
            backForm        = _this.find('.backform'),
            _panelUser        = _this.find('#panel-user'),
            _panelLogin       = _this.find('#panel-login'),
            _panelRecovery      = _this.find('#panel-recovery'),
            checkUserForm       = _this.find('form[name="check_user"]'),
            checkUserResponseTop  = checkUserForm.find('.form-response');

          var loginUserForm       = _this.find('form[name="log_user"]'),
            loginUserNonce      = loginUserForm.find('input[name="_wpnonce"]').val(),
            loginUserMailInput    = loginUserForm.find('input[name="username"]'),
            loginUserPwd      = loginUserForm.find('form[name="password"]'),
            loginUserResponseHeader = loginUserForm.find('.form-response-header'),
            loginUserResponse     = loginUserForm.find('.form-response'),
            _loginEmail       = loginUserForm.find('.user_exists_email'),
            _loginLetter      = loginUserForm.find('.user_exists_letter'),
            _loginHdrStrong     = loginUserForm.find('.fname');


            
            $('input[name="user_email"]').focus();
            console.log('FOCUS');

            //== CHECK USER FORM
            checkUserForm.on('submit', function(e) {
              e.preventDefault();

              var checkUserMailInput  = checkUserForm.find('input[name="user_email"]'),
                checkUserNonce    = checkUserForm.find('input[name="_wpnonce_usr"]').val(),
                checkUserMailVal  = checkUserMailInput.val(),
                checkUserSubmit   = checkUserForm.find('button[name="wp-submit"]'),
                spinner       = checkUserForm.find('.spinner');

              spinner.show();

              
              $.ajax({
                type: 'POST',
                url: vars.ajax_uri,
                data: {
                  action: 'spiga_custom_check_user',
                  user_email: checkUserMailVal,
                  security: checkUserNonce
                },
                beforeSend: function() {

                  checkUserMailInput.prop('disabled', true);
                  checkUserSubmit.prop('disabled', true);

                },
                success: function(response) {
                  

                  spinner.hide();

                  if (response.success) {

                    //== COOKIE
                    if (typeof Cookies !== "undefined") { 
                      var cookieValue = {
                        name: response.data.first_name,
                        email: response.data.email,
                        letter: response.data.first_letter
                      };
                      
                      Cookies.set(cookieRealtyflowCheckUser, JSON.stringify(cookieValue) , { expires: 20, path: '/' });

                    }

                
                    _panelUser.hide();
                    _panelLogin.fadeIn();
                    $('input[name="password"]').focus();
                    loginUserResponseHeader.html(response.data.message);
                    loginUserMailInput.val(response.data.email);

                    _loginEmail.html(response.data.email);
                    _loginLetter.html(response.data.first_letter);
                    

                  } else {

                    checkUserResponseTop.html(response.data.message);
                    checkUserMailInput.prop('disabled', false);
                    checkUserSubmit.prop('disabled', false);

                  }
                },
                error: function(xhr, textStatus, errorThrown) {
                  checkUserMailInput.prop('disabled', false);
                  checkUserSubmit.prop('disabled', false);
                  console.log(xhr.responseText);
                }
              });
            });

            //== RESET FORM
            backForm.on('click', function() {
              console.log('BACK FORM CLICKED 2');
              _panelUser.fadeIn();
              _panelLogin.hide();
              checkUserForm.find('input[name="user_email"]').prop('disabled', false);
              checkUserForm.find('button[name="wp-submit"]').prop('disabled', false);
              checkUserForm.find('input[name="user_email"]').val('');
              $('input[name="user_email"]').focus();
              Cookies.remove(cookieRealtyflowCheckUser, { path: '/' });
            });

            //== LOGIN SUBMIT
            loginUserForm.on('submit', function(e) {
              e.preventDefault();
              

              //== get cookie values
              if (typeof Cookies !== "undefined") { 
                var cookieValue = Cookies.get(cookieRealtyflowCheckUser);
                if (cookieValue !== undefined) {
                  console.log(cookieValue);
                  _loginEmail.html(cookieValue.email);
                  _loginLetter.html(cookieValue.letter);
                  
                }
              }

              //== login functions
              var loginUsernameInput  = loginUserForm.find('input[name="username"]'),
                loginPasswordInput  = loginUserForm.find('input[name="password"]'),
                loginUsernameVal  = loginUsernameInput.val(),
                loginPasswordVal  = loginPasswordInput.val(),
                checkLoginNonce   = loginUserForm.find('input[name="_wpnonce"]').val(),
                checkLoginSubmit  = loginUserForm.find('button[name="wp-submit"]'),
                spinner       = loginUserForm.find('.spinner');

              spinner.show();

              // Enviamos los datos del formulario por Ajax
              $.ajax({
                type: 'POST',
                url: vars.ajax_uri,
                data: {
                  action: 'spiga_custom_check_login',
                  username: loginUsernameVal,
                  password: loginPasswordVal,
                  rememberme: true,
                  security: checkLoginNonce
                },
                beforeSend: function(){
                  loginUserResponse.hide();
                  loginPasswordInput.prop('disabled', true);
                  checkLoginSubmit.prop('disabled', true);

                },
                success: function(response) {
                  
                  spinner.hide();
                  loginUserResponse.show();

                  if (response.success) {

                    loginUserResponse.html(response.data.message);
                    window.location.href = response.data.redirect;

                  } else {

                    loginUserResponse.html(response.data.message);
                    loginPasswordInput.prop('disabled', false);
                    checkLoginSubmit.prop('disabled', false);

                  }
                },
                error: function(xhr, status, error) {

                  
                },
                complete: function() {
                  loginPasswordInput.prop('disabled', false);
                  checkLoginSubmit.prop('disabled', false);
                }
              }); 
            });
            

            //== COOKIE FUNCTIONS
            if (typeof Cookies !== "undefined") { 
          
              var cookieValue = Cookies.get(cookieRealtyflowCheckUser);
              
              if (cookieValue !== undefined) {
                

                _panelUser.hide();
                _panelLogin.show();

                var cookieInfo = JSON.parse(cookieValue);

                _loginEmail.html(cookieInfo.email);
                _loginLetter.html(cookieInfo.letter);
                _loginHdrStrong.html(cookieInfo.name);

                loginUserMailInput.val(cookieInfo.email);

              
              } else {

                

              }

            }
        }); 

      

        // JavaScript to be fired on all pages
        var terminationEvent = 'onpagehide' in self ? 'pagehide' : 'unload';
        addEventListener(terminationEvent, function (event) {
        }, { capture: true });

        var mobx = $.ModuloBox({
          mediaSelector : '.mobx',
          scrollToZoom  : true,
        });
        mobx.init(); 
       
				

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Homepage
		'home': {
			init: function() {

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
    
		// Single
		'single' : {
			init: function(){
				
			}
		},

		// Page
		'page' : {
			init: function(){
			
			}
		},
		// About
		'about_us': {
			init: function() {
					// JavaScript to be fired on the about us page
			}
		},
    // Blog
		'blog' : {
			
			init: function() {
					
				},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}

		},
    'single_report' : {

      init: function(){

        // Escuchar el cambio en el checkbox
        $('#checkbox-resuelto').on('change', function() {
          if ($(this).is(':checked')) {
            $('#btn-publicar-post').prop('disabled', false);
         } else {
           $('#btn-publicar-post').prop('disabled', true);
         }
        });

        $('#btn-publicar-post').on('click', function(e) {
            e.preventDefault();
            console.log('PUBLICAR POST');
            // Obtener el ID del post
            var postID = $('#post_id').val();
            
            console.log(postID);

            // Realizar la solicitud AJAX
            $.ajax({
                url: vars.ajax_uri, // Utiliza la variable global de WordPress para la URL AJAX
                type: 'POST',
                data: {
                    action: 'publicar_post_ajax',
                    post_id: postID,
                },
                success: function(response) {
                    // Redireccionar si la publicación fue exitosa
                    if (response === 'success') {
                        window.location.href = vars.reports_url; // Reemplaza 'URL_DE_LA_PAGINA' con la URL a la que deseas redireccionar
                    }
                },
            });
        });

      }
    },

		// Login
    'page_template_template_login' : {
      init: function() {

        console.log('logn');

        //== LOGIN FORM
        $('form[name="loginform"]').on('submit', function(e) {
          e.preventDefault();
          var form    = $(this),
            _wpnonce  = form.find('input[name="_wpnonce"]').val(),
            username  = form.find('input[name="log"]').val(),
            password  = form.find('input[name="pwd"]').val(),
            rememberme  = form.find('input[name="rememberme"]').is(':checked'),
            submitBtn   = form.find('button[name="wp-submit"]'),
            spinner   = form.find('.spinner');
          
          submitBtn.prop('disabled', true);

          // Mostramos el loader
          spinner.show();
          

          // Enviamos los datos del formulario por Ajax
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_login',
              username: username,
              password: password,
              rememberme: rememberme,
              security: _wpnonce
            },
            success: function(response) {
              
              spinner.hide();

              if (response.success) {
                form.find('.form-response').html(response.data.message);
                window.location.href = response.data.redirect;
              } else {

                form.find('.form-response').html(response.data.message);

              }
            },
            error: function(xhr, status, error) {

              //console.log(xhr.responseText);
              //console.log(status);
              //console.log(error);
              
              
            },
            complete: function() {
              submitBtn.prop('disabled', false);
            }
          });
        });

        //== REGISTER FORM
        $('form[name="registerform"]').on('submit', function(e) {

          e.preventDefault();
          var form    = $(this),
            _wpnonce  = form.find('input[name="_wpnonce"]').val(),
            user_login  = form.find('input[name="user_login"]').val(),
            user_email  = form.find('input[name="user_email"]').val(),
            first_name  = form.find('input[name="first_name"]').val(),
            last_name   = form.find('input[name="last_name"]').val(),
            submitBtn   = form.find('button[name="wp-submit"]'),
            spinner   = form.find('.spinner');

            submitBtn.prop('disabled', true);
          
          // Mostramos el loader
          spinner.show();
          $('[data-msg]').hide();
          // Enviamos los datos del formulario por Ajax
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_register',
              user_login: user_login,
              user_email: user_email,
              first_name: first_name,
              last_name: last_name,
              security: _wpnonce
            },
            success: function(response) {
              // Escondemos el loader
              spinner.hide();
              if (response.success) {
                form.find('.form-response').html(response.data.message);
                window.location.href = response.data.redirect;
              } else {
                form.find('.form-response').html(response.data.message);
              }
            },
            error: function(xhr, status, error) {

              //console.log('ERROR REGISTER');
              //console.log(xhr.responseText);
              
              
            },
            complete: function() {
              submitBtn.prop('disabled', false);
            }
          });
        });

        //== LOST PASSWORD
        $('form[name="lostpasswordform"]').on('submit', function(e) {
          e.preventDefault();
          var form    = $(this),
            _wpnonce  = form.find('input[name="_wpnonce"]').val(),
            user_login  = form.find('input[name="user_login"]').val(),
            submitBtn   = form.find('button[name="wp-submit"]'),
            spinner   = form.find('.spinner');

          submitBtn.prop('disabled', true);

          // Mostramos el loader
          spinner.show();
          
          // enviar la solicitud AJAX
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_lostpassword',
              user_login: user_login,
              security: _wpnonce
            },
            success: function(response) {
              spinner.hide();
              //console.log(response);
              form.find('.form-response').html(response.data.message);
              form.find('input[name="user_login"]').val('');
              $('[data-msg]').hide();
            },
            error: function(xhr, status, error) {
              
            },
            complete: function() {
              spinner.hide();
              submitBtn.prop('disabled', false);
            }
          });
        });

        //== RESET PASSWORD
        $('form[name="resetpasswordform"]').on('submit', function(e) {
          e.preventDefault();
          var form = $(this),
            _wpnonce    = form.find('input[name="_wpnonce"]').val(),
            new_password  = form.find('input[name="new_password"]').val(),
            key       = form.find('input[name="key"]').val(),
            login       = form.find('input[name="login"]').val(),
            submitBtn     = form.find('button[name="wp-submit"]'),
            redirect    = form.find('input[name="redirect_to"]').val(),
            spinner     = form.find('.spinner');

          var strength_meter_wrapper  = form.find('.js-password-strength__meter-wrapper'),
            strength_meter_bar    = form.find('.password-strength__meter'),
            strength_req      = form.find('.js-password-strength__req');

          submitBtn.prop('disabled', true);

          // Mostramos el loader
          spinner.show();

          // enviar la solicitud AJAX
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_resetpassword',
              user_login: login,
              new_password: new_password,
              key: key,
              login: login,
              security: _wpnonce
            },
            success: function(response) {
              //console.log(response);
              if (response.success) {

                //console.log('success');
                form.find('.form-response').html(response.data.message);
                $('[data-msg]').hide();
                window.location.href = response.data.redirect;

                /*
                form.find('input[name="new_password"]').val('');
                strength_meter_wrapper.toggleClass('is-hidden');
                strength_meter_bar.css('width','0%');
                strength_req.removeClass('password-strength__req--no-met password-strength__req--met');
                */
              } else {
                //console.log(response.data);
                //console.log('UNSUCCESSSS 2');
                //console.log(response.data.message);
                form.find('.form-response').html(response.data.message);
              }
              
            },
            error: function(xhr, status, error) {
              //console.log(xhr);
              //console.log(status);
              //console.log(error);
            },
            complete: function() {
              spinner.hide();
              submitBtn.prop('disabled', false);
            }
          });
        });

      }
    },

    // Edit Profile
    'perfil': {
      init: function() {
        
        //console.log('form-control--error XXXX');

        if ($.fn.mask) {
          
          $('.birthday').mask('MM/DD/YYYY', {
            'translation': {
              M: {pattern: /[0-9]/},
              D: {pattern: /[0-9]/},
              Y: {pattern: /[0-9]/}
            }
          });
        }
        
        $('form[name="editprofileform"]').on('submit', function(e){
          e.preventDefault();

          var form = $(this),
            _wpnonce = form.find('input[name="_wpnonce"]').val(),
            first_name = form.find('input[name="first_name"]').val(),
            last_name = form.find('input[name="last_name"]').val(),
            user_email = form.find('input[name="user_email"]').val(),
            user_url = form.find('input[name="user_url"]').val(),
            user_phone = form.find('input[name="user_phone"]').val(),
            user_birthday = form.find('input[name="user_birthday"]').val(),
            submitBtn = form.find('button[name="wp-submit"]'),
            spinner = form.find('.spinner');


          //$('.js-alert').hide();
          spinner.show();
          submitBtn.prop('disabled', true);
          //console.log(_wpnonce);
          //console.log(vars.ajax_uri);
          // enviar la solicitud AJAX
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_update_profile',
              user_email: user_email,
              first_name: first_name,
              last_name: last_name,
              user_url: user_url,
              user_phone: user_phone,
              user_birthday: user_birthday,
              security: _wpnonce
            },
            success: function(response){
              //console.log(response);
              
              //console.log('Llegamos a SUCCESS B');

              form.find('.form-response').html(response.data.message);

              // Agrega la clase .form-control--error a los campos inválidos
              var fields = response.data.fields;
                if (fields) {
                  $.each(fields.split(','), function(index, value) {
                    $('input[name="' + value + '"]').addClass('form-control--error');
                  });
                }

                // Quita la clase .form-control--error de los campos que no están en fields
                var allFields = form.serializeArray().map(function(field) {
                  return field.name;
                });
                var invalidFields = fields ? fields.split(',') : [];
                var validFields = allFields.filter(function(field) {
                  return !invalidFields.includes(field);
                });
                $.each(validFields, function(index, value) {
                  $('input[name="' + value + '"]').removeClass('form-control--error');
                });

            },
            error: function(response, status, error){
              //console.log('Llegamos a ERROR');
              //console.log(response);
              //console.log(status);
              //console.log(error);
            },
            complete: function() {
              //console.log('Llegamos a COMPLETE B');
              spinner.hide();
              submitBtn.prop('disabled', false);
            }
            
          });
        });
        
      }
    },

    // Edit Password
    'pwd': {
      init: function() {

        //== EDIT PASSWORD FORM
        //console.log('edit password v3');
        $('form[name="editpassword"]').on('submit', function(e) {
          e.preventDefault();
          var form        = $(this),
            _wpnonce      = form.find('input[name="_wpnonce"]').val(),
            current_password  = form.find('input[name="current_password"]').val(),
            new_password    = form.find('input[name="new_password"]').val(),
            confirm_password  = form.find('input[name="confirm_password"]').val(),
            submitBtn       = form.find('button[name="wp-submit"]'),
            spinner       = form.find('.spinner');

          var strength_meter_wrapper  = form.find('.js-password-strength__meter-wrapper'),
            strength_meter_bar    = form.find('.password-strength__meter'),
            strength_req      = form.find('.js-password-strength__req');

          submitBtn.prop('disabled', true);
          
          // Mostramos el loader
          spinner.show();
          
          // enviar la solicitud AJAX
          $.ajax({
            type: 'POST',
            url: vars.ajax_uri,
            data: {
              action: 'spiga_custom_update_password',
              current_password: current_password,
              new_password: new_password,
              confirm_password: confirm_password,
              security: _wpnonce
            },
            success: function(response) {
              
              spinner.hide();

              //console.log(response);
              form.find('.form-response').html(response.data.message);

              if (response.success) {

                //console.log('response.success');

                form.find('input[name="current_password"]').val('');
                form.find('input[name="new_password"]').val('');
                form.find('input[name="confirm_password"]').val('');

                strength_meter_wrapper.toggleClass('is-hidden');
                strength_meter_bar.css('width','0%');
                strength_req.removeClass('password-strength__req--no-met password-strength__req--met');

               } else {

                //console.log('response.NOTsuccess');
                //console.log(response);
                

               }
            },
            error: function(xhr, status, error) {
              //console.log(xhr);
              //console.log(status);
              //console.log(error);
            },
            complete: function() {
              spinner.hide();
              submitBtn.prop('disabled', false);
            
            }
          });
        });
      }
    },

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');
			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});
			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};
	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
